import { defineStore } from 'pinia'
import { store } from '../index'
import { objMerge } from '@/utils/index'
import { getEnumList, getSelectItemRegionList } from '@/api/base'
import ui from '@/utils/ui'
// API
import { selectorDepartmentInfoList, selectorMyLeaderDepartmentList, selectorMyLeaderUserList, selectorPromotionInfoList } from '@/api/selector/index'
import { selectorProductBrandList, selectorProductInfoList, selectorProductYearList, selectorProductSpecificationList, selectorProductSaleList, selectorProductShelvesList } from '@/api/selector/index'
import { selectorMyProductShelvesList, selectorGeneralAccountRoleList, selectorSMSTemplateList } from '@/api/selector/index'
import { selectorFinanceInfoList, selectorLogisticsInfoList, selectorWDTShopList, selectorShopProductCategoryList, selectorSettlementDepartmentList } from '@/api/selector/index'

import { getSysMenuListApi } from '@/api/sysAdmin/sysMenu'
import { getMyWorkDeviceList } from '@/api/salePerson/myCustomer'
import { getSysSelectItemRegionList } from '@/api/sysAdmin/region'
// import { arrayToNestedObject } from '@/utils'

const remoteFuncMap = {
  SelectorSMSTemplateList: selectorSMSTemplateList,
  SelectorDepartmentInfoList: selectorDepartmentInfoList,
  SelectorMyLeaderDepartmentList: selectorMyLeaderDepartmentList,
  SelectorMyLeaderUserList: selectorMyLeaderUserList,
  SelectorPromotionInfoList: selectorPromotionInfoList,
  SelectorGeneralAccountRoleList: selectorGeneralAccountRoleList,
  SelectorFinanceInfoList: selectorFinanceInfoList,
  SelectorLogisticsInfoList: selectorLogisticsInfoList,
  SelectorWDTShopList: selectorWDTShopList,
  SelectorShopProductCategoryList: selectorShopProductCategoryList,
  SelectorProductBrandList: selectorProductBrandList,
  SelectorProductInfoList: selectorProductInfoList,
  SelectorProductYearList: selectorProductYearList,
  SelectorProductSpecificationList: selectorProductSpecificationList,
  SelectorProductSaleList: selectorProductSaleList,
  SelectorProductShelvesList: selectorProductShelvesList,
  SelectorSettlementDepartmentList: selectorSettlementDepartmentList,
  SelectorMyProductShelvesList: selectorMyProductShelvesList,
  SelectorMyWorkDeviceList: getMyWorkDeviceList,

  SysMenu: getSysMenuListApi,
  Region: getSelectItemRegionList,
  SysRegion: getSysSelectItemRegionList
}

//特殊类型转换
const valueConvertMap = {
  //高级接口：部门下拉列表
  // DepartmentInfoList: (list) => {
  //   return arrayToNestedObject(list, (obj, children) => {
  //     return {
  //       key: obj.key,
  //       value: parseInt(obj.value),
  //       disabled: obj.disabled,
  //       children
  //     }
  //   })
  // }
}

export const useResourceStore = defineStore('resource', {
  state: () => ({
    options: {},
    lang: {},
    rmOptions: {}
  }),
  getters: {},
  actions: {
    /**
     * 初始化选项
     */
    initOptions(): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          loadAllOptions((data: any) => {
            this.options = data
            resolve(data)
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * 数据选项
     */
    getRmOptions(key: RmOptionsType): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          const func: any = remoteFuncMap[key]
          if (this.rmOptions[key]) {
            resolve(this.rmOptions[key])
          } else if (!func) {
            const message = `getRmOptions -> "${key}" key not found`
            console.log(message)
            ui.message.error(message)
            resolve([])
          } else {
            func()
              .then((resp) => {
                const convertTo = valueConvertMap[key]
                this.rmOptions[key] = convertTo ? convertTo(resp.data) : resp.data
                resolve(this.rmOptions[key])
              })
              .catch((error) => {
                reject(error)
              })
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  }
})

const loadAllOptions = async (callback: any) => {
  const result = {}
  const allOptionsResp = await getEnumList()
  if (allOptionsResp.success) {
    objMerge(result, allOptionsResp.data)
  }
  callback(result)
}

export const useUserStoreWithOut = () => {
  return useResourceStore(store)
}
