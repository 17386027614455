import request from '@/axios'
import { GeneralSelectorDto } from './types'

/**
 * 超级管理：短信模板列表
 */
export const selectorSMSTemplateList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorSMSTemplateList', loader })
}

/**
 * 超级管理：部门信息列表
 */
export const selectorDepartmentInfoList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorDepartmentInfoList', loader })
}

/**
 * 超级管理：产品品牌列表
 */
export const selectorProductBrandList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductBrandList', loader })
}

/**
 * 超级管理：产品信息列表
 */
export const selectorProductInfoList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductInfoList', loader })
}

/**
 * 超级管理：产品年份列表
 */
export const selectorProductYearList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductYearList', loader })
}

/**
 * 超级管理：商品规格列表
 */
export const selectorProductSpecificationList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductSpecificationList', loader })
}

/**
 * 超级管理：销售商品列表
 */
export const selectorProductSaleList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductSaleList', loader })
}

/**
 * 超级管理：商品货架列表
 */
export const selectorProductShelvesList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorProductShelvesList', loader })
}

/**
 * 超级管理：投放账户列表
 */
export const selectorPromotionInfoList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorPromotionInfoList', loader })
}

/**
 * 超级管理：结算部门列表
 */
export const selectorSettlementDepartmentList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorSettlementDepartmentList', loader })
}

/**
 * 超级管理：远程搜索账号信息
 * @param keyword 关键词
 */
export const searchAccountInfoList = (
    keyword: string,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.get({ url: '/Selector/SearchAccountInfoList', params: { keyword }, loader })
}

/**
 * 超级管理：远程搜索工作微信
 * @param keyword 关键词
 */
export const searchWorkDeviceList = (
    keyword: string,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.get({ url: '/Selector/SearchWorkDeviceList', params: { keyword }, loader })
}

/**
 * 超级管理：账号一般角色列表
 */
export const selectorGeneralAccountRoleList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorGeneralAccountRoleList', loader })
}

/**
 * 超级管理：资金账户列表
 */
export const selectorFinanceInfoList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorFinanceInfoList', loader })
}

/**
 * 超级管理：物流信息列表
 */
export const selectorLogisticsInfoList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorLogisticsInfoList', loader })
}

/**
 * 超级管理：旺店通店铺列表
 */
export const selectorWDTShopList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorWDTShopList', loader })
}

/**
 * 超级管理：商城分类列表
 */
export const selectorShopProductCategoryList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorShopProductCategoryList', loader })
}

/**
 * 销售管理：我管理的部门列表
 */
export const selectorMyLeaderDepartmentList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorMyLeaderDepartmentList', loader })
}

/**
 * 销售管理：我管理的员工列表
 */
export const selectorMyLeaderUserList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorMyLeaderUserList', loader })
}



/**
 * 销售人员：远程搜索我的客户信息
 * @param keyword 关键词
 */
export const searchMyCustomerList = (
    keyword: string,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.get({ url: '/Selector/SearchMyCustomerList', params: { keyword }, loader })
}

/**
 * 销售人员：我的商品货架列表
 */
export const selectorMyProductShelvesList = (
    loader?: RequestLoader
): Promise<IResponse<GeneralSelectorDto[]>> => {
    return request.get({ url: '/Selector/SelectorMyProductShelvesList', loader })
}